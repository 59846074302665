import React from "react"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { responsive, lazyload, placeholder } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import "swiper/css"
import "swiper/css/scrollbar"

const SimilarWork = props => {
  var { collaboratorTitle, collaborators } = props.pagedata

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  //create the image from the cloudinary url

  var hideSection = false

  if (collaborators === null) {
    collaborators = []
    hideSection = true
  }

  const swiperItems = collaborators.map((collaborator, index) => {
    const slug = collaborator.collaboratorImage.mediaDetails.file
    const altText = collaborator.collaboratorImage.altText

    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug}`)

    theImage.quality("70")
    theImage.resize(fill().width(800).height(463))
    theImage.format("auto")

    const linkUrl = collaborator.collaboratorLinkUrl.uri

    return (
      <SwiperSlide key={index} className={`swiper-slide d-flex flex-column`}>
        <Link to={linkUrl} title={collaborator.collaboratorLinkUrl.title}>
          <AdvancedImage
            cldImg={theImage}
            plugins={[
              lazyload({
                rootMargin: "0px",
                threshold: 0.25,
              }),
              responsive({ steps: 400 }),
              placeholder({ mode: "blur" }),
            ]}
            alt={altText}
            className={`mw-100`}
          />
        </Link>
        <h4 className="mt-3 lower color-light-grey">
          {collaborator.collaboratorName}
        </h4>
        <h3 className="lower">{collaborator.collaboratorSubtext}</h3>
      </SwiperSlide>
    )
  })

  return (
    <>
      <div className="pb-6 pt-6 hide-swiper-overflow">
        <div className="container">
          <div className="col-12 mb-3 mb-lg-5">
            <h2>{collaboratorTitle}</h2>
          </div>
        </div>
        <div className="container">
          <Swiper
            slidesPerView={1.2}
            spaceBetween={20}
            breakpoints={{
              767: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            className={`collab_swipe`}
          >
            {swiperItems}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default SimilarWork
