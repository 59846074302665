import React from "react"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { lazyload } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as solStyle from "./client-solutions.module.css"
import * as s from "./client-additional.module.css"

const ClientAdditional = ({ pagedata }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const sectionOutput = pagedata.clientsSmAdditionalSections.map(
    (theInfo, index) => {
      if (theInfo.clientsSmSelectContentType === "vimeo") {
        const videoVimeoURL = theInfo.clientsSmAddVimeoUrl
        return (
          <>
            <div className="container mb-6">
              <div className="row">
                <div className="col-12">
                  <div class="video-embed-container">
                    <iframe
                      src={videoVimeoURL}
                      allow="autoplay; fullscreen"
                      allowFullScreen=""
                      style={{
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                      }}
                      title="case study video"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      if (theInfo.clientsSmSelectContentType === "text_center") {
        return (
          <div key={index} className="container mb-6 mt-6">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h3 className="lower mb-3">{theInfo.clientsSmTextFwTitle}</h3>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <div
                  className={`${s.para} ${solStyle.double_break}`}
                  dangerouslySetInnerHTML={{
                    __html: theInfo.clientsSmTextFwContent,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )
      }

      if (theInfo.clientsSmSelectContentType === "image_fw") {
        let imgUri = theInfo.clientsSmFwAddImage.mediaDetails.file
        let theImage = cld.image(
          `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
        )
        theImage.quality("70")
        theImage.resize(fill().width(1320))
        theImage.format("auto")

        return (
          <div key={index} className="container mb-6 mt-6">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <AdvancedImage
                  cldImg={theImage}
                  plugins={[
                    lazyload({
                      rootMargin: "250px",
                      threshold: 0.25,
                    }),
                  ]}
                  className="mw-100"
                  style={{
                    padding: "1rem",
                    background: "rgba(255, 255, 255, .15)",
                  }}
                  alt={theInfo.clientsSmFwAddImage.altText}
                />
              </div>
            </div>
          </div>
        )
      }

      if (theInfo.clientsSmSelectContentType === "text_2_col") {
        let theColWidth = "col-12 col-md-10 col-lg-9"
        if (theInfo.clientsSmTwoColNumberOfColumns > 1) {
          theColWidth = "col-12 col-lg-6"
        }
        return (
          <div key={index} className="container mb-6 mt-6">
            <div className="row gx-5">
              <div className={`${theColWidth}`}>
                <h3 className="lower mb-3 px-4">
                  {theInfo.clientsSmTextTwoColTitleLeft} &nbsp;
                </h3>

                <div
                  className={`${s.para} ${solStyle.double_break} px-4`}
                  dangerouslySetInnerHTML={{
                    __html: theInfo.clientsSmTextTwoColContentLeft,
                  }}
                ></div>
              </div>
              {theInfo.clientsSmTwoColNumberOfColumns > 1 && (
                <div className="col-12 col-lg-6">
                  <h3 className="lower mb-3 px-4">
                    {theInfo.clientsSmTextTwoColTitleRight} &nbsp;
                  </h3>
                  <div
                    className={`${s.para} ${solStyle.double_break} px-4`}
                    dangerouslySetInnerHTML={{
                      __html: theInfo.clientsSmTextTwoColContentRight,
                    }}
                  ></div>
                </div>
              )}
            </div>
            {theInfo.clientsSmTwoColNumberOfColumns > 2 && (
              <div className="row gx-5 mt-3">
                <div className="col-12 col-lg-6">
                  <h3 className="lower mb-3 px-4">
                    {theInfo.clientsSmTextTwoColTitleLeftRow2} &nbsp;
                  </h3>

                  <div
                    className={`${s.para} ${solStyle.double_break} px-4`}
                    dangerouslySetInnerHTML={{
                      __html: theInfo.clientsSmTextTwoColContentLeftRow2,
                    }}
                  ></div>
                </div>

                <div className="col-12 col-lg-6">
                  <h3 className="lower mb-3 px-4">
                    {theInfo.clientsSmTextTwoColTitleRightRow2} &nbsp;
                  </h3>
                  <div
                    className={`${s.para} ${solStyle.double_break} px-4`}
                    dangerouslySetInnerHTML={{
                      __html: theInfo.clientsSmTextTwoColContentRightRow2,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        )
      }

      if (theInfo.clientsSmSelectContentType === "image_2_col") {
        let imgUriL = theInfo.clientsSmFwAddTwoColImageLeft.mediaDetails.file
        let theImageL = cld.image(
          `${process.env.GATSBY_API_FOLDER_URL}${imgUriL}`
        )
        theImageL.quality("70")
        theImageL.resize(fill().width(700))
        theImageL.format("auto")

        let imgUriR = theInfo.clientsSmFwAddTwoColImageRight.mediaDetails.file
        let theImageR = cld.image(
          `${process.env.GATSBY_API_FOLDER_URL}${imgUriR}`
        )
        theImageR.quality("70")
        theImageR.resize(fill().width(700))
        theImageR.format("auto")

        return (
          <div key={index} className="container mb-6 mt-6">
            <div className="row justify-content-center">
              <div className="col-6">
                <AdvancedImage
                  cldImg={theImageL}
                  plugins={[
                    lazyload({
                      rootMargin: "250px",
                      threshold: 0.25,
                    }),
                  ]}
                  className="mw-100"
                  style={{
                    padding: "1rem",
                    background: "rgba(255, 255, 255, .15)",
                  }}
                  alt={theInfo.clientsSmFwAddTwoColImageLeft.altText}
                />
              </div>
              <div className="col-6">
                <AdvancedImage
                  cldImg={theImageR}
                  plugins={[
                    lazyload({
                      rootMargin: "250px",
                      threshold: 0.25,
                    }),
                  ]}
                  className="mw-100"
                  style={{
                    padding: "1rem",
                    background: "rgba(255, 255, 255, .15)",
                  }}
                  alt={theInfo.clientsSmFwAddTwoColImageRight.altText}
                />
              </div>
            </div>
          </div>
        )
      }
    }
  )

  return (
    <>
      {sectionOutput}
      <div className="mt-6">
        <br />
      </div>
    </>
  )
}

export default ClientAdditional
