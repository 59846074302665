import React from "react"
import { cloudImage } from "../utils.js"
import * as solStyle from "./client-solutions.module.css"
import * as style from "./client-impact.module.css"

const ClientImpact = ({ pagedata }) => {
  const lTitle =
    pagedata.cfClientsSingleAll.singleClientImpactLeftHandSideTitle || "The Impact"
  const theIntro =
    pagedata.cfClientsSingleAll.singleClientImpactRightHandSideParagraph ||
    "{the intro}"
  const theStats = pagedata.cfClientsSingleAll.singleClientImpactStats

  const imgFull =
    pagedata.cfClientsSingleAll.singleClientImpactFullWidthImage.mediaDetails.file
  const mimeType =
    pagedata.cfClientsSingleAll.singleClientImpactFullWidthImage.mimeType

  const fullImgUrl = cloudImage(imgFull, mimeType, 1920, true)
  
  return (
    <>
      <div className="container mb-6">
        <div className="row">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <h3 className="lower px-4">{lTitle}</h3>
          </div>
          <div className="col-12 col-lg-8">
            <p
              className={`${solStyle.double_break} px-4`}
              dangerouslySetInnerHTML={{ __html: theIntro }}
            />
            {theStats !== null && ( 
              <div className="row text-center text-md-start mt-100">
                {theStats.map((stat, index) => {
                  if (stat.singleClientImpactStatNumber !== null) {
                    return (
                      <div key={index} className="col-12 col-md-4 mb-5 mb-md-0">
                        <h2 className={style.big_number}>
                          {stat.singleClientImpactStatNumber}
                        </h2>
                        <p>{stat.singleClientImpactStatTextLabel}</p>
                      </div>
                    )
                  } else {
                    return <></>
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="d-flex align-items-center bg_image image-block"
        style={{
          backgroundImage: fullImgUrl,
          backgroundPosition: "top",
        }}
      >
        <div className="dark_image_overly"></div>
      </div>
    </>
  )
}

export default ClientImpact
