import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { isSafariLess16 } from "../utils.js"

const ClientHero = ({ pagedata }) => {
  // var title = pagedata.cfClientsSingleAll.singleClientHeroTitle.replace(
  //   " X ",
  //   '<span class="small-x">&times;</span> '
  // )
  // title = title.replace(" x ", '<br><span class="small-x">&times;</span> ')
  const title = pagedata.cfClientsSingleAll.singleClientHeroTitle
  const theIntro =
    pagedata.cfClientsSingleAll.singleClientHeroIntro || "{the intro}"
  let theHerotype = pagedata.cfClientsSingleAll.clientHeroIsBoxed || false
  const isVideo = pagedata.cfClientsSingleAll.clientCaseHeroIsVideo || false
  const altText = pagedata.featuredImage.node.altText || "Client Hero Image"

  if (isVideo) {
    theHerotype = true
  }

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const imgUri = pagedata.featuredImage.node.mediaDetails.file
  const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

  console.log("tyoe:" + isVideo)

  const HeroImage = () => {
    theImage.quality("70")
    theImage.format("auto")
    let fwSizeWidth = 800
    let fwSizeHeight = 450

    if (!theHerotype) {
      fwSizeWidth = 1920
      fwSizeHeight = 650
      theImage.resize(fill().width(fwSizeWidth).height(fwSizeHeight))
      return (
        <AdvancedImage
          cldImg={theImage}
          alt={altText}
          className={`w-100 mb-6`}
        />
      )
    } else {
      if (isVideo) {
        const videoHeroURL = pagedata.cfClientsSingleAll.caseStudyVimeoId
        return (
          <>
            <div className="container mb-6">
              <div className="row">
                <div className="col-12">
                  <div class="video-embed-container">
                    <iframe
                      src={videoHeroURL}
                      allow="autoplay; fullscreen"
                      allowFullScreen=""
                      style={{
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                      }}
                      title=""
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      } else {
        theImage.resize(fill().width(fwSizeWidth))
        return (
          <>
            <div className="container mb-6">
              <div className="row">
                <div className="col-12">
                  <AdvancedImage
                    cldImg={theImage}
                    alt={altText}
                    className={`w-100`}
                  />
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <div
        className="hero-small d-flex align-items-center darkgrey_bg"
        style={{ minHeight: "500px" }}
      >
        <div className="container">
          <div id="hide-row-when-nav-open" className="row align-items-center">
            <div className="col-12 col-lg-6 mt-5 color-white animated-headline">
              <h1
                className="lower header-fade-in ps-4"
                style={{ lineHeight: "48px" }}
              >
                <span
                  class="d-block"
                  style={{ fontFamily: "MaisonNeue-Medium, system-ui, Arial" }}
                >
                  {title}
                </span>
                <span class="d-inline-block small-x me-1">&times;</span>
                <span class="d-inline-block" style={{ fontSize: "38px" }}>
                  Waracle
                </span>
              </h1>
            </div>
            <div className="col-12 col-lg-6 mt-5 pe-4 color-white animated-headline">
              <div className="animated-headline-wrap">
                <h2 className="lower new_style" style={{ fontSize: "30px" }}>
                  <span
                    className={`new_style_addit ${
                      isSafariV16 ? "safari_16" : ""
                    }`}
                  >
                    {theIntro}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeroImage />
    </>
  )
}

export default ClientHero
